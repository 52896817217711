import * as R from 'ramda';
import React from 'react';
import { node } from 'prop-types';
import { useLocation } from '@poly/client-routing';
import { AuthRoutesRedirect } from '@poly/client-utils';

import { routes } from '../routes.js';

// checkSkipTokenValidation :: { pathname: String } -> Boolean
const checkSkipTokenValidation = R.compose(
  R.includes(R.__, [routes.changePassword, routes.forgotPassword]),
  R.propOr('', 'pathname'),
);

export function LoginLayout({ children }) {
  const location = useLocation();

  return (
    <>
      {children}
      <AuthRoutesRedirect
        skipTokenValidation={checkSkipTokenValidation(location)}
        homeRoute={routes.home}
        authRoutesList={[
          routes.login,
          routes.forgotPassword,
          routes.changePassword,
        ]}
      />
    </>
  );
}

LoginLayout.propTypes = {
  children: node.isRequired,
};
